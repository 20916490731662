require('lightgallery')

$(document).ready(function() {
    $("#lightgallery").lightGallery();
    $("#lightgallery2").lightGallery();
    $("#lightgalleryFotos").lightGallery({
        selector: ".foto-destaque-padrao"
    });

    $(".imagem-foto-conteudo").first().css("display", 'none');

    $(".imagem-foto-conteudo").on("click", function(){
        $(".imagem-foto-conteudo").css('display', 'block');
        $(this).css('display', 'none');
        alvo = parseInt($(this).data("click"));

        $(".foto-destaque-conteudo").addClass("d-none");
        $(".foto-destaque-conteudo").removeClass("foto-ativa");

        $("#destaque"+alvo).removeClass("d-none");
        $("#destaque"+alvo).addClass("foto-ativa");
    });
});
