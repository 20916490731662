$(function () {


    var image = this.querySelectorAll('.banner-image');
    // var headerHeight = this.getElementById('header').offsetHeight;

    // // alert('oi', headerHeight.value);

    image.forEach(img => {
        img.setAttribute('style', 'height: 640px;');
    });

    $('.menuCategorias').on('show.bs.collapse', function () {
        $("#seta" + $(this).data('seta')).addClass('rotate180');
    });

    $('.menuCategorias').on('hide.bs.collapse', function () {
        $("#seta" + $(this).data('seta')).removeClass("rotate180");
    });

    $(".manipula-container-busca").on("click", function(e){
        e.preventDefault();

        if(!($(".et_pb_menu__search-container").hasClass(".et_pb_menu__search-container--visible"))){
            $(".et_pb_menu__search-container").css("opacity", "1");
            $(".et_pb_menu__search-container").css("height", "100%");
            $(".et_pb_menu__search-container").css("visibility", "visible");
            $(".transition-navbar").css("visibility", "hidden");

            if($(window).width() > 991){
                $(".nav-item").fadeOut();
            }else{
                $(".et_pb_menu__search-container").css("margin-top", "0.5rem");
                $(".et_pb_menu__search-container").css("margin-bottom", "0.5rem");
            }
        }

            if($(window).width() > 991){
                if ($(window).scrollTop() > 167) {
                    setTimeout(function(){
                        $("#search4").trigger("focus");
                    }, 250);
                }else{
                    setTimeout(function(){
                        $("#search2").trigger("focus");
                    }, 250);
                }
            }else{
                if ($(window).scrollTop() > 500) {
                    setTimeout(function(){
                        $("#search3").trigger("focus");
                    }, 250);
                }else{
                    setTimeout(function(){
                        $("#search1").trigger("focus");
                    }, 250);
                }
            }
    });

    $(".searchfocus2").focusout(function(){
        if($(window).width() > 991){
            setTimeout(function(){
                $(".nav-item").fadeIn();
            }, 500);
        }
        $(".et_pb_menu__search-container").css("margin-top", "0rem");
        $(".et_pb_menu__search-container").css("margin-bottom", "0rem");
        $(".et_pb_menu__search-container").css("opacity", "0");
        $(".et_pb_menu__search-container").css("visibility", "hidden");
        $(".et_pb_menu__search-container").css("height", "0px");
        $(".transition-navbar").css("visibility", "visible");
    });

    $(".searchfocus1").focusout(function(){
        if($(window).width() > 991){
            setTimeout(function(){
                $(".nav-item").fadeIn();
            }, 500);
        }
        $(".et_pb_menu__search-container").css("margin-top", "0rem");
        $(".et_pb_menu__search-container").css("margin-bottom", "0rem");
        $(".et_pb_menu__search-container").css("opacity", "0");
        $(".et_pb_menu__search-container").css("visibility", "hidden");
        $(".et_pb_menu__search-container").css("height", "0px");
        $(".transition-navbar").css("visibility", "visible");
    });

    if($(window).width() > 991){
        if (document.querySelector('.container-banner .tns-nav')) {
            $(".container-banner .tns-nav").css("bottom", "calc(50% - "+($(".container-banner .tns-nav").height() / 2)+"px)");
        }
    }

    $(window).resize(function(){
        if($(window).width() > 991){
            if (document.querySelector('.container-banner .tns-nav')) {
                $(".container-banner .tns-nav").css("bottom", "calc(50% - "+($(".container-banner .tns-nav").height() / 2)+"px)");
            }
        }else{
            if (document.querySelector('.container-banner .tns-nav')) {
                $(".container-banner .tns-nav").css("bottom", "5%");
            }
        }
    });

    $("#botao-abre-whats").on("click", function(){
       if($("#absolute-whats").css("display") == "none"){
           $("#absolute-whats").css("display", "flex");
           $("#botao-abre-whats").css("background-color", "#fff");
           $("#botao-abre-whats").css("color", "#26D367");
           $("#icone-muda-cor-whats").css("color", "#26D367");

           $( "#absolute-whats" ).animate({
               opacity: 1
           }, 100, function() {
           });
       }else{
           $("#botao-abre-whats").css("background-color", "#26D367");
           $("#botao-abre-whats").css("color", "#fff");
           $("#icone-muda-cor-whats").css("color", "#fff");

           $( "#absolute-whats" ).animate({
               opacity: 0
           }, 100, function() {

           });

           setTimeout(function(){
               $("#absolute-whats").css("display", "none");
           },500);
       }
    });

    setTimeout(function(){
            $(".botao-whatsapp").css("animation", "shake 0.8s");
            $(".botao-whatsapp").css("opacity", "1");
        }, 1000);

    setTimeout(function(){
            $(".botao-whatsapp2").css("animation", "shake 0.8s");
            $(".botao-whatsapp2").css("opacity", "1");
        }, 1000);

    setTimeout(function(){
            $(".botao-whatsapp3").css("animation", "shake 0.8s");
            $(".botao-whatsapp3").css("opacity", "1");
        }, 1000);

    $(".altera-categoria-busca").on("click", function(e){
        e.preventDefault();
        categoria = $(this).data("id-categoria");
        texto = $(this).text();

        if(categoria > 0){
            $(".todas-as-categorias-alterar").removeClass("d-none");
        }else{
            $(".todas-as-categorias-alterar").addClass("d-none");
        }

        $(".categoria_selecionada").val(categoria);
        $(".text-alterado").text(texto);
    });

    if($(window).width() > 991){
        if($(window).scrollTop() > 167){
            $('#aparece-rolagem').css("visibility", "visible");
            $('#aparece-rolagem').css("opacity", "1");
            $('#aparece-rolagem').css("z-index", "4");
        }
    }else{
        if($(window).scrollTop() > 500){
            $('#aparece-rolagem').css("visibility", "visible");
            $('#aparece-rolagem').css("opacity", "1");
            $('#aparece-rolagem').css("z-index", "4");
        }
    }

    
    $(window).bind('scroll', function () {
        if($(window).width() > 991){
            if ($(window).scrollTop() > 167) {
                $('#aparece-rolagem').css("visibility", "visible");
                $('#aparece-rolagem').css("opacity", "1");
                $('#aparece-rolagem').css("z-index", "4");
            } else {
                $('#aparece-rolagem').css("visibility", "hidden");
                $('#aparece-rolagem').css("opacity", "0");
                $('#aparece-rolagem').css("z-index", "0");
            }
        }else{
            if ($(window).scrollTop() > 500) {
                $('#aparece-rolagem').css("visibility", "visible");
                $('#aparece-rolagem').css("opacity", "1");
                $('#aparece-rolagem').css("z-index", "4");
            } else {
                $('#aparece-rolagem').css("visibility", "hidden");
                $('#aparece-rolagem').css("opacity", "0");
                $('#aparece-rolagem').css("z-index", "0");
            }
        }
    });

    if($('.descricao-banner').length){
        $(".descricao-banner").css("opacity", "1");
        $(".descricao-banner").css("transform", "translateY(0px)");
    }

    $(".animate-ancora").on("click", function(){
        $('html, body').animate({
            scrollTop: $($(this).data("href")).offset().top - 135
        }, 800);
    });

    try{
        $("#popup-banner-flutuante").modal('show');
    }catch (e){

    }

    $(".mais-informacoes").on("click", function(e){
        e.preventDefault();

        if($(".descricao").css("max-height") == "none"){
            $(".descricao").css("max-height", "60px");
            $(this).text("mais informações");
        }else{
            $(".descricao").css("max-height", "none");
            $(this).text("menos informações");
        }
    });

    // if ($("#modalNotification")) {
    //     $("#modalNotification").modal("show");
    // }
    $('[data-toggle="tooltip"]').tooltip();

    $("#estado_id").change(function () {
        const codigo_uf = $(this).val();

        $("#municipio_id")
            .find("option")
            .remove()
            .end()
            .append(
                $("<option>", {
                    value: "",
                    text: "Carregando...",
                })
            );

        $.ajax({
            url: "/api/municipios/" + codigo_uf,
        }).done(function (data) {
            data = data.results;

            $("#municipio_id").find("option").remove().end();

            if (data.length > 0) {
                $("#municipio_id").append(
                    $("<option>", {
                        value: "",
                        text: "Escolha um Município",
                    })
                );

                $.each(data, function (i, item) {
                    $("#municipio_id").append(
                        $("<option>", {
                            value: item.id,
                            text: item.text,
                        })
                    );
                });
            } else {
                $("#municipio_id").append(
                    $("<option>", {
                        value: "",
                        text: "Estado não possui Municípios",
                    })
                );
            }
        });
    });

    $('[data-toggle="popover"]').popover();

    $(".legenda").change(function () {
        const url = $(this).data("url");
        const legenda = $(this).val();
        const token = $(this).data("csrf");

        const input = $(this);

        $.ajax({
            url: url,
            type: "PUT",
            data: {
                legenda: legenda,
                _token: token,
            },
        }).done(function (data) {
            if (data == "success") {
                input.addClass("is-valid");
            } else {
                input.addClass("is-invalid");
            }
        });
    });

    $(".btn-upload-multiplo-modal").click(function () {
        $("#uploadMultiploModal").modal("show");
        $("#uploadMultiploModal #uploadModalForm").attr(
            "action",
            $(this).attr("data-href")
        );

        if ($(this).attr("data-multiplo")) {
            $("#uploadMultiploModal #uploadModalForm #files").prop(
                "multiple",
                true
            );
        } else {
            $("#uploadMultiploModal #uploadModalForm #files").prop(
                "multiple",
                false
            );
        }

        if ($(this).attr("data-arquivo")) {
            $("#uploadMultiploModal #uploadModalForm #files").attr(
                "data-arquivo",
                1
            );
        } else {
            $("#uploadMultiploModal #uploadModalForm #files").attr(
                "data-arquivo",
                0
            );
        }
    });

    // $('body').delegate(".btn-upload-multiplo-modal", "click", function () {

    // });

    $("#uploadMultiploModal #uploadModalForm").on("submit", function () {
        $("#uploadMultiploModal .modal-body .sec-primary").hide();
        $("#uploadMultiploModal .modal-body .sec-secondary").show();
    });

    // function teste() {
    //
    //     const witdh = $(window).width();
    //     const href = $('.whatsapp').attr('href');
    //
    //     console.log(witdh, href);
    //
    //     if (witdh > 900) {
    //         $('.whatsapp').attr('href', 'https://web.whatsapp.com/send?text=' + $('.whatsapp').data('href'));
    //     } else {
    //         $('.whatsapp').attr('href', 'https://web.whatsapp.com/send?text=' + $('.whatsapp').data('href'));
    //     }
    //
    // }
    //
    // $(window).resize(teste());
});

function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
    )
        return false;

    // Valida DVs
    tamanho = cnpj.length - 2;
    numeros = cnpj.substring(0, tamanho);
    digitos = cnpj.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
}

window.checkCnpj = function (input) {
    if (!validarCNPJ(input.value)) {
        input.value = "";
        alert("CNPJ invalido.");
    }
};
